import {
  faFacebook,
  faInstagram,
  faPinterest,
  faSquareXTwitter,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import item_news1 from "../images/news_img1.jpg";
import item_news3 from "../images/news_img3.jpg";
import aboutUs_img from "../images/aboutUs.jpg";

export const aboutUs = aboutUs_img;

export const contactInfo = {
  phone: "",
  email: "anastasia@pfbeads.com",
  address: "",
};

export const homePageDescArr = [
  "Philippa Fine Beads is an artisan business specializing in unique and affordable beads designs. Our products are hand crafted from high quality Czech glass beads. Our products fit any occasion - pick up our casual necklaces for a brunch with friends, a day on the beach, a business meeting, or a nightclub party - our unique designs are always in the trend and will set you out of the crowd!",
];

export const iconLinks = [
  {
    id: "facebook_icon",
    icon: faFacebook,
    url: "https://www.facebook.com",
    size: "lg",
    color: "#140062",
  },
  {
    icon: faInstagram,
    url: "https://www.instagram.com",
    size: "lg",
    id: "instagram_icon",
    color: "#140062",
  },
  {
    icon: faPinterest,
    url: "https://www.pinterest.com",
    size: "lg",
    id: "pinterest_icon",
    color: "#140062",
  },
  {
    icon: faSquareXTwitter,
    url: "https://twitter.com",
    size: "lg",
    id: "twitter_icon",
    color: "#140062",
  },
  {
    icon: faTiktok,
    url: "https://www.tiktok.com",
    size: "",
    id: "tiktok_icon",
    color: "#140062",
  },
  {
    icon: faYoutube,
    url: "https://www.youtube.com",
    size: "lg",
    id: "youtube_icon",
    color: "#140062",
  },
  {
    icon: faEnvelope,
    url: contactInfo.email,
    size: "lg",
    id: "envelope_icon",
    color: "#140062",
  },
];

export const newsHome_data = [
  {
    id: 3,
    name: "News 3",
    title: "Get ready for Christmas!",
    date: "November 18, 2024",
    link: "https://www.facebook.com/torontoartcrawl?__cft__[0]=AZUWaF26tzMt_RtzYHXfLm9ixZaJBpG1NYfLX6n31PxEnTK-Pzo2z_S3GcoH4Aj9Qc69ff2IPYNxCe2mg8EUdec1H8cJD3lbzYZKFB52WES8_oolguODJCDP9LPGpk4F_C7-EPxVoIYwu-rZtrQnPfs7BYARIgLAlh6jrYfWA4dmlEgmA7WM3nIgNIop4wM9jiHx8H0FAb6JdNKtn-LkhJQJ&__tn__=-]K-R",
    text_link: true,
    img_url: item_news3,
    text: [
      "Get ready for Christmas - visit our stand at ",
      "Toronto Art Crawl",
      " to find unique gifts for your loved ones!",
    ],
  },
  {
    id: 2,
    name: "News 2",
    title:
      "Have you known that the art of making glass beads is on the Unesco list of the Intangible Cultural Heritage of Humanity?",
    date: "October 10, 2024",
    link: "https://ich.unesco.org/en/RL/the-art-of-glass-beads-01591",
    text_link: true,
    img_url: 0,
    text: ["Check the link below to learn more about that fascinating craft "],
  },
  {
    id: 1,
    name: "News 1",
    title: "Our website is live!",
    date: "October 10, 2024",
    link: "",
    img_url: item_news1,
    text: ["Check our latest products and place your order"],
  },
];
