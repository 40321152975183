import React from "react";
import { Link } from "react-router-dom";
import "./itemCard2.css";

function ItemCard({ props }) {
  // let discount = props.discount.toFixed(2);
  // let price = props.price.toFixed(2);
  return (
    <Link
      to={"/products/" + props.id}
      className="itemCard_container d-flex-col aling-items-justify-center"
    >
      <div className="itemCard_img_div">
        <img src={props.img_url[0].url} alt={props.name} className="card_img" />
      </div>
      {/* <div className="itemCardData_div d-flex-col">
        <div className="itemCard_itemName d-flex-col">{props.name}</div>
        <div className="itemCard_priceData d-flex-row">
          <div className="itemCard_discount">${discount}</div>
          <div className="itemCard_price">${price}</div>
        </div>
      </div> */}
    </Link>
  );
}

export default ItemCard;
