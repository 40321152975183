import React from "react";
import "./contactUs.css";

function ContactUs({ contactInfo }) {
  return (
    <section className="contactUs_section">
      <div className="contactUsTitle_div">
        <h4 className="contactUsTitle">CONTACT US</h4>
      </div>
      <div className="contactData">
        <div className="contactsData_div ">
          {contactInfo.phone.length > 0 && (
            <div className="container_contacts_phone">
              <div className="contacts_phone_title">Phone:</div>
              <div className="contacts_phone contactUs_data">
                {contactInfo.phone}
              </div>
            </div>
          )}

          {contactInfo.email.length > 0 && (
            <div className="container_contacts_email d-flex-row">
              <div className="contacts_email_title">Email:</div>
              <div className="contacts_email contactUs_data">
                {contactInfo.email}
              </div>
            </div>
          )}

          {contactInfo.address.length > 0 && (
            <div className="container_contacts_address">
              <div className="contacts_address_title">Address:</div>
              <div className="contacts_address contactUs_data">
                {contactInfo.address}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
