import React from "react";
import "./mainBtn.css";
import { Link } from "react-router-dom";

function MainBtn({ link, siteNew, text }) {
  const handleClick = (e) => {
    e.preventDefault();
    window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      {siteNew === "y" ? (
        <div className="buyOn_div">
          <div className="buyOn_title etsy_buyOn_title text-shadow">
            Buy on Etsy
          </div>
          <Link to={""} className="linkBtn_all" onClick={handleClick}>
            {text}
          </Link>
        </div>
      ) : (
        <Link to={link} className="linkBtn_all">
          {text}
        </Link>
      )}
    </>
  );
}

export default MainBtn;
