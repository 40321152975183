import React from "react";
import "./textComp.css";

function textComp({ textArr }) {
  return (
    <div className="textComp_container">
      {textArr.map((item, index) => {
        return (
          <p className="textComp_div" key={index}>
            {item}
          </p>
        );
      })}
    </div>
  );
}

export default textComp;
