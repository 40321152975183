import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../utils/Logo/Logo";
import "./header.css";

function Header() {
  return (
    <>
      <header>
        <div className="header_div ">
          <div className="logo_div">
            <Logo />
          </div>
          <div className="header_navMenu">
            <Link to="/" className="header_navLink">
              Home
            </Link>
            <Link to="/products" className="header_navLink">
              Products
            </Link>
          </div>
          <div className="shoppingCart_div"></div>
        </div>
      </header>
    </>
  );
}

export default Header;
