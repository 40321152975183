import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./utils/ScrollToTop/ScrollToTop";
import ShopifyProduct1 from "./utils/ShopifyProduct1/ShopifyProduct1";

import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import ProductsList from "./components/ProductsList/ProductsList";
import Product from "./components/Product/Product";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ShopifyProduct1 />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="products" element={<ProductsList />}></Route>
          <Route path="/products/:id" element={<Product />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
