import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ShopifyProductButton() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== "/products/1") return;

    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    const loadScript = () => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = scriptURL;
        script.async = true;
        script.onload = resolve;
        document.head.appendChild(script);
      });
    };

    const createShopifyComponent = () => {
      const client = window.ShopifyBuy.buildClient({
        domain: "34unpg-10.myshopify.com",
        storefrontAccessToken: "68917caa9ea59e36f4de5a39b7423dfe",
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        const node = document.getElementById("product-component-1729709524270");

        // Проверяем, есть ли уже iframe в элементе
        if (node && !node.querySelector("iframe")) {
          ui.createComponent("product", {
            id: "8911104442623",
            node: node,
            moneyFormat: "%24%7B%7Bamount%7D%7D",
            options: {
              product: {
                styles: {
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "calc(25% - 20px)",
                      "margin-left": "20px",
                      "margin-bottom": "50px",
                    },
                  },
                  button: {
                    ":hover": {
                      "background-color": "#8c4ea4",
                    },
                    "background-color": "#9c57b6",
                    ":focus": {
                      "background-color": "#8c4ea4",
                    },
                  },
                },
                contents: {
                  img: false,
                  title: false,
                  price: false,
                },
                text: {
                  button: "Add to cart",
                },
              },
              productSet: {
                styles: {
                  products: {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px",
                    },
                  },
                },
              },
              modalProduct: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  button: false,
                  buttonWithQuantity: true,
                },
                styles: {
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px",
                    },
                  },
                  button: {
                    ":hover": {
                      "background-color": "#8c4ea4",
                    },
                    "background-color": "#9c57b6",
                    ":focus": {
                      "background-color": "#8c4ea4",
                    },
                  },
                },
                text: {
                  button: "Add to cart",
                },
              },
              option: {},
              cart: {
                styles: {
                  button: {
                    ":hover": {
                      "background-color": "#8c4ea4",
                    },
                    "background-color": "#9c57b6",
                    ":focus": {
                      "background-color": "#8c4ea4",
                    },
                  },
                },
                text: {
                  total: "Subtotal",
                  button: "Checkout",
                },
              },
              toggle: {
                styles: {
                  toggle: {
                    "background-color": "#9c57b6",
                    ":hover": {
                      "background-color": "#8c4ea4",
                    },
                    ":focus": {
                      "background-color": "#8c4ea4",
                    },
                  },
                },
              },
            },
          });
        }
      });
    };

    const initializeShopify = async () => {
      if (window.ShopifyBuy) {
        createShopifyComponent();
      } else {
        await loadScript();
        createShopifyComponent();
      }
    };

    initializeShopify();

    return () => {
      const scriptElements = document.querySelectorAll(
        `script[src="${scriptURL}"]`
      );
      scriptElements.forEach((script) => script.remove());
    };
  }, [pathname]);

  return null;
}

export default ShopifyProductButton;
