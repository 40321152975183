import React, { useState } from "react";
import "./slideshow.css";

function Slideshow({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="slideshow_container">
      <div className="imageLarge_div">
        <img
          src={images[currentIndex].url}
          alt={images[currentIndex].name}
          className="imageLarge_div"
        />
      </div>
      <div className="thumbnail-container">
        {images.map((image, index) => (
          <div key={index} className="imageSmall">
            <img
              src={image.url}
              alt={`Thumbnail ${index}`}
              className={`thumbnail ${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            />
          </div>
        ))}
      </div>
      <div className="slideShowBtns_div">
        <button onClick={prevImage} className="buttonSG">
          Prev
        </button>
        <button onClick={nextImage} className="buttonSG">
          Next
        </button>
      </div>
    </div>
  );
}

export default Slideshow;
