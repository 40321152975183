import React from "react";
import "./productsList.css";
import { Link } from "react-router-dom";

import { productsArr, productsDescArr } from "../../data/products";
import ProductsItemCard from "../../utils/ProductsItemCard/ProductsItemCard";
import PreOrder from "../../utils/PreOrder/PreOrder";
import TextComp from "../../utils/TextComp/TextComp";

function Products() {
  return (
    <main className="products_section_container">
      <section className="products_section">
        <div className="productsIntro_div">
          <h1 className="">Products</h1>
          <TextComp textArr={productsDescArr} />
        </div>

        <div className="productsList_div">
          {productsArr.map((item, index) => {
            if (item.url === "") return "";

            return (
              <Link
                to={"/products/" + item.id}
                className="itemCard_products"
                key={index}
              >
                <ProductsItemCard data={item} />
              </Link>
            );
          })}
        </div>
        <PreOrder />
      </section>
    </main>
  );
}

export default Products;
