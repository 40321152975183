import React from "react";
import "./product.css";
import { productsArr } from "../../data/products";

import PreOrder from "../../utils/PreOrder/PreOrder";
import MainBtn from "../../utils/MainBtn/MainBtn";

import { useParams, Link } from "react-router-dom";
import RelatedProducts from "../../utils/RelatedProducts/RelatedProducts";
import Slideshow from "../../utils/Slideshow/Slideshow";

function Product() {
  const { id } = useParams();
  let num = Number(id);
  let temp = productsArr.filter((item) => item.id === num);
  let relatedProducts = productsArr.filter(
    (item) => item.type === temp[0].type && item.id !== temp[0].id
  );
  let product = temp[0];
  let price = " - No data";
  if (product.price > 0) price = product.price.toFixed(2);

  return (
    <main className="product_section_container">
      <section className="product_section">
        <div className="product_sectionLink">
          <Link to="/products">Back to Products</Link>
        </div>
        <h2 className="productName">{product.name}</h2>
        <Slideshow images={product.img_url} />
        <div className="productLength_div">
          <div className="lengthTitle">Length:</div>
          <div className="lengthData_div">
            <div className="lengthSM_div">{product.lenghtSM + " cm"}</div>
            <div className="lengthSlash">/</div>
            <div className="lengthIN_div">{product.lenghtIN + " in"}</div>
          </div>
        </div>
        <div className="productCardEl productBrDesc">{product.desc}</div>
        <div className="productPriceData_div">
          <div className="product_section_priceData product_category">
            <span>Category: </span>
            <span className="spanProductCategory">{product.cat}</span>
          </div>

          <div className="product_section_priceData product_price">
            <span className="spanProductPrice">{"Price:"}</span> ${price}
          </div>
        </div>
        <div className="itemId_div">
          <span className="itemId_class">Item identifier: </span>{" "}
          {product.item_id}
        </div>
        <PreOrder />

        <div className="purchaseOptions">
          <div className="purchaseEtsy">
            {product.url.length > 0 && (
              <MainBtn link={product.url} siteNew={"y"} text={"Buy"} />
            )}
          </div>
          <div className="purchaseShopify">
            {product.item_id === "00001" && (
              <div className="buyOn_div">
                <div className="buyOn_title shopify_buyOn_title text-shadow">
                  Buy on Shopify
                </div>
                <div id="product-component-1729709524270"></div>
              </div>
            )}
          </div>
        </div>

        {relatedProducts.length > 0 && (
          <RelatedProducts data={relatedProducts} />
        )}
      </section>
    </main>
  );
}

export default Product;
