import "./logo.css";
import React from "react";

function Logo() {
  return (
    <div className="logo d-flex-col justify-center">
      <span className="line1">PHILIPPA</span>
      <span className="line2">FINE BEADS</span>
    </div>
  );
}

export default Logo;
