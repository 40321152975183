import React from "react";
import "./preOrder.css";

import { contactInfo } from "../../data/data";

function PreOrder() {
  const email = contactInfo.email;
  const subject = "Your Subject Here";
  const body = "Hello, ";

  return (
    <div className="preoderNote">
      To preorder, please contact us at{" "}
      <a
        className="preoderNote_link"
        href={`mailto:${email}?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`}
      >
        {" " + contactInfo.email}
      </a>
    </div>
  );
}

export default PreOrder;
