import React from "react";
import "./relatedProducts.css";
import { Link } from "react-router-dom";

function RelatedProducts({ data }) {
  return (
    <div className="relatedProduct_div">
      <div className="relatedProdSectionTitle">Related products</div>
      <div className="relatProdItems_div">
        {data.map((el, index) => {
          return (
            <Link
              to={"/products/" + el.id}
              className="relatProdItem_div"
              key={index}
            >
              <img
                src={el.img_url[0].url}
                alt={el.name}
                className="relatProd_img"
              />
              <div className="relatProdNamePrice">
                <div className="relatProdTitle">{el.name}</div>
                <div className="relatProdPrice">${el.price}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default RelatedProducts;
