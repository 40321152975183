import img_1_1 from "../images/01-CorallBlueGold/Image_1.JPG";
import img_1_IMG_6972 from "../images/01-CorallBlueGold/IMG_6972.JPG";
import img_1_IMG_6977 from "../images/01-CorallBlueGold/IMG_6977.JPG";
import img_1_IMG_6978 from "../images/01-CorallBlueGold/IMG_6978.JPG";
import img_1_IMG_6993 from "../images/01-CorallBlueGold/IMG_6993.JPG";
import img_4_Image_2 from "../images/04-LeafsBlueRed/Image_2.JPG";
import img_4_IMG_6966 from "../images/04-LeafsBlueRed/IMG_6966.JPG";
import img_4_IMG_6979 from "../images/04-LeafsBlueRed/IMG_6979.JPG";
import img_4_IMG_6980 from "../images/04-LeafsBlueRed/IMG_6980.JPG";
import img_4_IMG_6990 from "../images/04-LeafsBlueRed/IMG_6990.JPG";
import img_5_IMG_7653 from "../images/05-HeartsGreenRed/IMG_7653.jpg";
import img_5_IMG_7654 from "../images/05-HeartsGreenRed/IMG_7654.jpg";
import img_5_IMG_7655 from "../images/05-HeartsGreenRed/IMG_7655.jpg";
import img_5_IMG_7656 from "../images/05-HeartsGreenRed/IMG_7656.jpg";
import img_5_IMG_7657 from "../images/05-HeartsGreenRed/IMG_7657.jpg";
import img_5_IMG_7659 from "../images/05-HeartsGreenRed/IMG_7659.jpg";
import img_5_IMG_7661 from "../images/05-HeartsGreenRed/IMG_7661.jpg";
import img_6_Image_3 from "../images/06-OrnamentsGreenWhite/Image_3.jpg";
import img_6_IMG_6961 from "../images/06-OrnamentsGreenWhite/IMG_6961.jpg";
import img_6_IMG_6987 from "../images/06-OrnamentsGreenWhite/IMG_6987.jpg";
import img_7_IMG_6969 from "../images/07-ChokerGreenRed/IMG_6969.jpg";
import img_7_IMG_6968 from "../images/07-ChokerGreenRed/IMG_6968.jpg";
import img_7_Image_4 from "../images/07-ChokerGreenRed/Image_4.jpg";
import img_7_IMG_6984 from "../images/07-ChokerGreenRed/IMG_6984.jpg";
import img_7_IMG_6991 from "../images/07-ChokerGreenRed/IMG_6991.jpg";
import img_7_IMG_6992 from "../images/07-ChokerGreenRed/IMG_6992.jpg";
import img_8_IMG_7604 from "../images/08-AnglesGreenRed/IMG_7604.jpg";
import img_8_IMG_7606 from "../images/08-AnglesGreenRed/IMG_7606.jpg";
import img_8_IMG_7602 from "../images/08-AnglesGreenRed/IMG_7602.jpg";
import img_10_IMG_7627 from "../images/10-HeartsRedGold/IMG_7627.jpg";
import img_10_IMG_7628 from "../images/10-HeartsRedGold/IMG_7628.jpg";
import img_10_IMG_7623 from "../images/10-HeartsRedGold/IMG_7623.jpg";
import img_10_IMG_7625 from "../images/10-HeartsRedGold/IMG_7625.jpg";
import img_10_IMG_7626 from "../images/10-HeartsRedGold/IMG_7626.jpg";

export const productsDescArr = [
  "Discover our exquisite range of beaded jewelry, designed to elevate your style and express individuality. Each piece features unique, meticulously crafted beads in captivating colors and shapes, ensuring a distinctive look that stands out. Lightweight and comfortable, these accessories are perfect for both casual outings and special occasions.",
  "With beautiful combinations that evoke charm and sophistication, they make ideal gifts for loved ones or a delightful treat for yourself. Embrace the unique allure of our creations, which embody love and creativity. Explore our selection today and find the perfect accessory that resonates with your personal style and enhances any ensemble!",
];

export const productsTypes = [
  "Corall",
  "Ornaments",
  "Leafs",
  "Hearts",
  "Choker",
  "Angles",
];

export const productsArr = [
  {
    id: 1,
    item_id: "00001",
    name: "Beaded Necklace Blue Gold",
    desc: "This elegant beaded necklace is adorned with a striking combination of blue and gold beads. Its lightweight design ensures comfort, making it suitable for a variety of occasions. The unique, natural allure of the beads imparts a sense of sophistication, rendering it a timeless addition to any jewelry collection. An ideal accessory for enhancing any ensemble.",
    brief_desc:
      "Sophisticated blue and gold beaded necklace; an elegant accessory that is perfect for any occasion.",
    price: 49.99,
    lenghtSM: 43,
    lenghtIN: 16.93,
    type: productsTypes[0],
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_1_1, name: "Beaded Necklace Blue Gold 1" },
      { id: 2, url: img_1_IMG_6972, name: "Beaded Necklace Blue Gold 2" },
      { id: 3, url: img_1_IMG_6977, name: "Beaded Necklace Blue Gold 3" },
      { id: 4, url: img_1_IMG_6978, name: "Beaded Necklace Blue Gold 4" },
      { id: 5, url: img_1_IMG_6993, name: "Beaded Necklace Blue Gold 5" },
    ],
    url: "https://www.etsy.com/ca/listing/1768995519/beaded-necklace-blue-gold?click_key=4b932f234139bcc73ed992133178a93daa15a91b%3A1768995519&click_sum=a6ba49fc&ref=shop_home_feat_1&frs=1",
  },
  {
    id: 4,
    item_id: "00004",
    name: "Beaded Necklace Blue Red",
    desc: "Discover the captivating beauty of our Beaded Necklace Blue Red in striking blue and red hues. This stunning piece features meticulously crafted beads that reflect light with every movement, evoking a sense of vibrancy and elegance. Designed for comfort, its lightweight construction makes it perfect for both casual and formal occasions. The unique combination of colors adds a bold statement to any outfit, ensuring you stand out wherever you go. Whether you're dressing up for a night out or adding flair to your everyday look, this necklace is an essential accessory that elevates your style with ease. Embrace sophistication and make it yours today!",
    brief_desc:
      "Stunning blue and red beaded necklace; vibrant, elegant, versatile for any occasion.",
    price: 29.99,
    lenghtSM: 42,
    lenghtIN: 16.54,
    type: productsTypes[2],
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_4_Image_2, name: "Beaded Necklace Blue Red 1" },
      { id: 2, url: img_4_IMG_6966, name: "Beaded Necklace Blue Red 2" },
      { id: 3, url: img_4_IMG_6979, name: "Beaded Necklace Blue Red 3" },
      { id: 4, url: img_4_IMG_6980, name: "Beaded Necklace Blue Red 4" },
      { id: 5, url: img_4_IMG_6990, name: "Beaded Necklace Blue Red 5" },
    ],
    url: "https://www.etsy.com/ca/listing/1754802512/beaded-necklace-blue-red?click_key=a8bbcc98edfb47a8c677d0b7023c0ae674ed0a48%3A1754802512&click_sum=fae729ed&ref=shop_home_active_6&frs=1",
  },
  {
    id: 5,
    item_id: "00005",
    name: "Beaded Necklace Green Red",
    desc: "Introducing our charming Beaded Necklace Green Red featuring a delightful combination of green and red heart-shaped beads. This playful accessory adds a pop of color and whimsy to any outfit, making it perfect for both casual wear and special occasions. Crafted for comfort, its lightweight design ensures easy wear throughout the day. The unique heart shapes symbolize love and friendship, making it a thoughtful gift for someone special or a lovely treat for yourself. Elevate your style with this enchanting necklace and showcase your personality effortlessly!",
    brief_desc:
      "Charming green and red heart-shaped beaded necklace; playful, colorful, and versatile.",
    price: 29.99,
    lenghtSM: 43,
    lenghtIN: 16.93,
    type: productsTypes[3],
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_5_IMG_7653, name: "Beaded Necklace Green Red 1" },
      { id: 2, url: img_5_IMG_7654, name: "Beaded Necklace Green Red 2" },
      { id: 3, url: img_5_IMG_7655, name: "Beaded Necklace Green Red 3" },
      { id: 4, url: img_5_IMG_7656, name: "Beaded Necklace Green Red 4" },
      { id: 5, url: img_5_IMG_7657, name: "Beaded Necklace Green Red 5" },
      { id: 6, url: img_5_IMG_7659, name: "Beaded Necklace Green Red 6" },
      { id: 7, url: img_5_IMG_7661, name: "Beaded Necklace Green Red 7" },
    ],
    url: "https://www.etsy.com/ca/listing/1782081404/beaded-necklace-green-red?click_key=2b2f1a63dfd1ab8c31e4056073dddcd5c0041b05%3A1782081404&click_sum=dc36668c&ref=shop_home_active_2&frs=1",
  },
  {
    id: 6,
    item_id: "00006",
    name: "Beaded Necklace Green White",
    desc: "Elevate your style with our stunning Beaded Necklace Green White featuring an exquisite combination of green and white ornament-shaped beads. This eye-catching piece adds a touch of elegance and sophistication to any outfit, making it perfect for both everyday wear and special occasions. The lightweight design ensures comfortable wear, allowing you to showcase this beautiful accessory throughout the day. The necklace is uniquely crafted, creating a distinctive look that stands out. Whether you're dressing up for an event or adding flair to your casual attire, this necklace is a must-have addition to your jewelry collection!",
    brief_desc:
      "Stunning green and white beaded necklace; elegant, lightweight, perfect for any occasion.",
    price: 29.99,
    lenghtSM: 37,
    lenghtIN: 14.57,
    type: productsTypes[1],
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_6_Image_3, name: "Beaded Necklace Green White 1" },
      { id: 2, url: img_6_IMG_6961, name: "Beaded Necklace Green White 2" },
      { id: 3, url: img_6_IMG_6987, name: "Beaded Necklace Green White 3" },
    ],
    url: "https://www.etsy.com/ca/listing/1754805814/beaded-necklace-green-white?click_key=bd04cf3f99c4835b930325754bfd0707e9afa6d4%3A1754805814&click_sum=fd22ea20&ref=shop_home_active_5&frs=1",
  },
  {
    id: 7,
    item_id: "00007",
    name: "Beaded Choker Necklace",
    desc: "The Beaded Choker Necklace is a unique and rare find that will enhance your jewelry collection. This exquisite piece showcases an intricate arrangement of colorful beads, and meticulously handcrafted for a distinctive look. Its striking design adds a bold yet elegant flair to any outfit, making it suitable for both casual and formal occasions. With its lightweight construction, it ensures comfortable wear throughout the day. Stand out with this exceptional accessory that embodies individuality and creativity. Don’t miss the opportunity to own this stunning choker — an extraordinary addition that perfectly reflects your unique style!",
    brief_desc:
      "The Beaded Choker Necklace is a unique find, showcasing colorful beads and lightweight, comfortable design.",
    price: 29.99,
    lenghtSM: 37,
    lenghtIN: 14.57,
    type: productsTypes[4],
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_7_IMG_6969, name: "Beaded Choker Necklace 1" },
      { id: 2, url: img_7_IMG_6968, name: "Beaded Choker Necklace 2" },
      { id: 3, url: img_7_Image_4, name: "Beaded Choker Necklace 3" },
      { id: 4, url: img_7_IMG_6984, name: "Beaded Choker Necklace 4" },
      { id: 5, url: img_7_IMG_6991, name: "Beaded Choker Necklace 5" },
      { id: 6, url: img_7_IMG_6992, name: "Beaded Choker Necklace 6" },
    ],
    url: "https://www.etsy.com/ca/listing/1754810456/beaded-choker-necklace?click_key=53aaa75a37efe68e7a8981d8d4f90f54a2e1e89d%3A1754810456&click_sum=f3367637&ref=shop_home_active_7&frs=1",
  },
  {
    id: 8,
    item_id: "00008",
    name: "Beaded Necklace Green Red (Angles)",
    desc: "Our Beaded Necklace Green Red in striking green and red angles is a bold statement piece that will elevate your jewelry collection. This rare find features a captivating array of uniquely shaped beads, meticulously crafted to create a stunning visual effect. The vibrant colors harmonize beautifully, making it an eye-catching accessory for any occasion. Lightweight and comfortable, this necklace is perfect for both casual outings and formal events. Its distinctive design ensures you stand out, showcasing your unique sense of style. Whether worn alone or layered with other favorites, this necklace adds a touch of elegance and creativity to any outfit. Don’t miss out on this exceptional addition to your collection!",
    brief_desc:
      "Elegant angels necklace is lightweight, eye-catching, and perfect for any occasion.",
    price: 49.99,
    lenghtSM: 45.5,
    lenghtIN: 17.91,
    type: productsTypes[5],
    cat: "Necklace",
    img_url: [
      {
        id: 1,
        url: img_8_IMG_7604,
        name: "Beaded Necklace Green Red (Angles) 1",
      },
      {
        id: 2,
        url: img_8_IMG_7606,
        name: "Beaded Necklace Green Red (Angles) 2",
      },
      {
        id: 3,
        url: img_8_IMG_7602,
        name: "Beaded Necklace Green Red (Angles) 3",
      },
    ],
    url: "https://www.etsy.com/ca/listing/1782089130/beaded-necklace-green-red?click_key=47cdc781c94f4802b950246e2d027d8845ec63fa%3A1782089130&click_sum=c80af9e5&ref=shop_home_feat_4&frs=1",
  },
  {
    id: 10,
    item_id: "00010",
    name: "Beaded Necklace Red Gold (Hearts)",
    desc: "This exquisite Beaded Necklace Red Gold in vibrant red and gold features charming heart-shaped beads, symbolizing love and connection for every occasion. Its eye-catching design adds a romantic flair to any outfit, making it perfect for both casual gatherings and formal events. The lightweight construction ensures comfortable wear, allowing you to showcase this beautiful accessory throughout the day. The necklace is carefully crafted, creating a unique and distinctive look. Whether you’re treating yourself or gifting it to someone special, this necklace is a wonderful way to express your heartfelt sentiments and enhance your style effortlessly!",
    brief_desc:
      "Charming hearts necklace, symbolizing love and connection for every occasion.",
    price: 25.99,
    lenghtSM: 41,
    lenghtIN: 16.14,
    type: productsTypes[3],
    cat: "Necklace",
    img_url: [
      {
        id: 1,
        url: img_10_IMG_7627,
        name: "Beaded Necklace Red Gold (Hearts) 1",
      },
      {
        id: 2,
        url: img_10_IMG_7628,
        name: "Beaded Necklace Red Gold (Hearts) 2",
      },
      {
        id: 3,
        url: img_10_IMG_7623,
        name: "Beaded Necklace Red Gold (Hearts) 3",
      },
      {
        id: 4,
        url: img_10_IMG_7625,
        name: "Beaded Necklace Red Gold (Hearts) 4",
      },
      {
        id: 5,
        url: img_10_IMG_7626,
        name: "Beaded Necklace Red Gold (Hearts) 5",
      },
    ],
    url: "https://www.etsy.com/ca/listing/1772979742/beaded-necklace-red-gold?click_key=3f56cdc25ea59a94f0bedc1ed21f222cdb6f996d%3A1772979742&click_sum=000d4baa&ref=shop_home_active_2&frs=1&cns=1",
  },
];
