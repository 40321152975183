import { Outlet } from "react-router-dom";
import Header from "../../utilComps/Header/Header";
import Footer from "../../utilComps/Footer/Footer";
import "./layout.css";

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
